import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { useTheme } from "@mui/material/styles";
import RequestDemo from "../RequestDemo";

import heroIllustration from "../../assets/new/metR.gif";
import { Link } from "react-router-dom";

const Section1 = () => {
  const theme = useTheme();

  return (
    <Box
      component={"div"}
      sx={{
        paddingY: "2rem",

        [theme.breakpoints.up("md")]: {
          flexDirection: "column",
          // height: "calc(100vh - 80px)",
        },
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Grid
          item
          md={6}
          style={{
            overflow: "hidden",
            position: "relative",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <img
              src={heroIllustration}
              alt={``}
              style={{
                borderRadius: "0.5rem",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                flex: "0 0 auto",
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",

              flex: "0 0 auto",

              [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                color: "var(--white)",
                fontSize: {
                  xs: "28px",
                  sm: "35px",
                  md: "38px",
                  lg: "40px",
                },

                lineHeight: {
                  xs: "40px",
                  sm: "40px",
                  md: "45px",
                  lg: "45px",
                },
              }}
            >
              Lorem ipsum dolor sit amet consectetur,
            </Typography>
            <Box
              sx={{
                marginTop: "1rem",
                marginBottom: "2rem",
                lineHeight: "28.13px",
                fontSize: "20px",
                color: "var(--white)",
                opacity: "0.8",
                fontWeight: "400",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "16px",
                },
              }}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptatem, saepe vel dolor iste animi, deleniti accusamus
              eligendi corrupti odio modi, Lorem ipsum dolor sit amet
              consectetur, adipisicing elit. Optio voluptatem ullam illum
              dignissimos est unde, officia, voluptas quis, nesciunt excepturi
              soluta velit cumque molestias obcaecati. Corrupti, asperiores?
              Quam, cum debitis?
            </Box>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Link
                to={"/request-demo"}
                className="requestButton"
                style={{ zIndex: "1" }}
              >
                <RequestDemo />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Section1;
