import { Box } from "@mui/material";
import React from "react";

const Product = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          marginTop: "1.5rem",
          marginX: "auto",
        }}
      >
        <img
          src="  https://preview.uiuxassets.com/opalin/media/content/opalin-dashboard.png"
          alt=""
          style={{
            borderRadius: "0.5rem",
          }}
        />
      </Box>
    </>
  );
};

export default Product;
