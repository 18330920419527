import React from "react";
import CustomContainer from "../CustomContainer";
import { Box, Container, Typography } from "@mui/material";
import Product from "./Product";

const YourProduct = () => {
  return (
    <CustomContainer>
      <Container
        sx={{
          maxWidth: "1250px !important",
        }}
      >
        <Box sx={{ marginY: "5rem" }}>
          <Typography
            align="center"
            sx={{
              fontSize: "42px",
              fontWeight: "700",
              color: "var(--text-primary)",
            }}
          >
            Present Your Product
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: "var(--text-gray)",
              paddingY: "1rem",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            Opalin helps you present your business in a wide variety of ways.
            Display full-width images, divide content in a single or multiple
            columns, anything to make your product or service stand out!
          </Typography>
          <Product />
        </Box>
      </Container>
    </CustomContainer>
  );
};

export default YourProduct;
